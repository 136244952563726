import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_e = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - E'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>E</h2>
     <LatQuotesIntro />
    
     <p><b>Ei incumbit probatio qui</b> - The onus of proving a fact rests upon the man.</p>
<p><b>Ei incumbit probatio qui dicit, non qui negat</b> - The burden of the proof lies upon him who affirms, not he who denies.</p>
<p><b>Error, qui non resistitur approbatur</b> - An error not resisted is approved.</p>
<p><b>Et cetera</b> - Other things of that type.</p>
<p><b>Ex cathedra</b> - With official authority.</p>
<p><b>Ex concessis</b> - In view of what has already been accepted/</p>
<p><b>Ex dolo malo actio non oritur</b> - A right of action cannot arise out of fraud.</p>
<p><b>Ex facie</b> - On the fact of it.</p>
<p><b>Ex gratia</b> - Out of kindness, voluntary.</p>
<p><b>Ex nihilo nil fit</b> - From nothing nothing comes.</p>
<p><b>Ex nudo pacto actio non oritur</b> - No action arises on a contract without a consideration.</p>
<p><b>Ex parte</b> - Proceeding brought by one person in the absence of another.</p>
<p><b>Ex post facto</b> - By reason of a subsequent act.</p>
<p><b>Ex praecedentibus et consequentibus optima fit interpretatio</b> - The best interpretation is made from things preceding and following.</p>
<p><b>Ex turpi causa non oritur actio</b> - No action arises on an immoral contract.</p>
<p><b>Exceptio probat regulam</b> - An exception proves the rule.</p>
<p><b>Executio est executio juris secundum judicium</b> - Execution is the fulfillment of the law in accordance with the judgment.</p>
<p><b>Executio est finis et fructus legis</b> - An execution is the end and the fruit of the law.</p>
<p><b>Executio legis non habet injuriam</b> - Execution of the law does no injury.</p>
<p><b>Extra legem positus est civiliter mortuus</b> - One out of the pale of the law (i.e. an outlaw) is civilly dead.</p>

   </Layout>
  )
}

export default LatQuotes_e
